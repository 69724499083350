<template>
    <div>
        <v-card tile v-if="credentials && credentials['access_key_id']">
            <v-system-bar dark v-if="credentials['is_sandbox']" color="warning">Sandbox mode</v-system-bar>
            <v-system-bar dark v-else color="error">Production mode</v-system-bar>
            <v-row>
                <v-col cols="8" class="pb-0">
                    <v-simple-table dense>
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <th width="200">Label</th>
                                    <td>
                                        {{ credentials['label'] }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Access Key ID</th>
                                    <td>
                                        {{ credentials['access_key_id'] }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Secret Access Key
                                        <v-icon small class="ml-2" @click="secretAccessKeyShown = !secretAccessKeyShown" v-html="secretAccessKeyShown ? 'mdi-eye-off' : 'mdi-eye'" />
                                    </th>
                                    <td>
                                        <span v-html="secretAccessKeyShown ? credentials['secret_access_key'] : '***********'" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Balance</th>
                                    <td>
                                        ${{ credentials['account_balance']['available_balance'] }}
                                        <span v-if="credentials['account_balance']['on_hold_balance']">
                                            (on hold: {{ credentials['account_balance']['on_hold_balance'] }})
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
                <v-col cols="4" class="text-center" align-self="center">
                    <v-btn
                        outlined
                        small
                        v-if="credentials.is_sandbox"
                        class="my-1"
                        width="200"
                        height="40"
                        color="error"
                        :loading="settingActiveSandboxMode"
                        @click="setActiveSandboxMode(false)">
                        <v-icon>mdi-swap-horizontal</v-icon> Change to<br>production mode
                    </v-btn>
                    <v-btn
                        outlined
                        small
                        v-else
                        class="my-1"
                        width="200"
                        height="40"
                        color="warning"
                        :loading="settingActiveSandboxMode"
                        @click="setActiveSandboxMode(true)">
                        <v-icon>mdi-swap-horizontal</v-icon> Change to<br>sandbox mode
                    </v-btn>
                    <br>
                    <v-btn
                        outlined
                        small
                        class="mt-1"
                        width="200"
                        color="grey"
                        @click="$refs.dialogManageCredentials.show()">
                        Manage credentials
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>

        <v-card v-else>
            <v-card-text>
                MTurk account is not set. 
                <v-btn
                    text
                    color="indigo"
                    @click="$refs.dialogManageCredentials.show()">
                Set credentials
            </v-btn>
            </v-card-text>
        </v-card>

        <dialog-manage-credentials ref="dialogManageCredentials" :client="client" />
    </div>
</template>

<script>
import DialogManageCredentials from './DialogManageCredentials'

export default {
    components: {
        DialogManageCredentials
    },
    data: () => ({
        secretAccessKeyShown: false,
        settingActiveSandboxMode: false,
    }),
    props: ['duct', 'credentials', 'client'],
    methods: {
        async setActiveSandboxMode(isSandbox) {
            this.settingActiveSandboxMode = true;
            await this.client.mturk.setActiveSandboxMode({ is_sandbox: isSandbox });
            this.settingActiveSandboxMode = false;
        }
    }
}
</script>

<template>
    <div>
        <v-row style="height:300px" align="center" class="text-center">
            <v-col>
                <div class="text-h3 pa-6">Amazon Mechanical Turk</div>
                <v-btn
                    outlined
                    class="ma-2"
                    color="indigo"
                    :href="workerSiteUrl"
                    target="_blank"
                >
                    <v-icon left>mdi-account-group</v-icon>
                    Workers<span v-if="isSandbox">' Sandbox</span>
                </v-btn>
                <v-btn
                    outlined
                    class="ma-2"
                    color="indigo"
                    :href="requesterSiteUrl"
                    target="_blank"
                >
                    <v-icon left>mdi-account-circle</v-icon>
                    Requesters<span v-if="isSandbox">' Sandbox</span>
                </v-btn>
            </v-col>
        </v-row>

        <v-row align="center" justify="center">
            <v-col cols="9">
                <div class="text-h6 mb-5">Credentials</div>
                <credentials-card :duct="duct" :credentials="credentials" :client="client"></credentials-card>
            </v-col>
        </v-row>

        <v-row align="center" justify="center" class="my-6">
            <v-col cols="9">
                <div class="text-h6 mb-5">Services</div>
                <page-cards></page-cards>
            </v-col>
        </v-row>

    </div>
</template>
<script>
import PageCards from './PageCards'
import CredentialsCard from './CredentialsCard'

export default {
    components: {
        PageCards,
        CredentialsCard,
    },
    props: ['duct', 'credentials', 'client'],
    computed: {
        isSandbox() {
            return this.credentials && this.credentials.is_sandbox;
        },
        workerSiteUrl() {
            return this.isSandbox ? 'https://workersandbox.mturk.com' : 'https://worker.mturk.com';
        },
        requesterSiteUrl() {
            return this.isSandbox ? 'https://requestersandbox.mturk.com' : 'https://requester.mturk.com';
        }
    }
}
</script>

<template>
    <v-row>
        <v-col lg="3" sm="4" cols="12" v-for="card in cards" :key="card.to">
            <v-card tile hover :ripple="false" class="pa-2" height="200" :to="card.to">
                <div class="pt-4 pl-4"><v-icon large :color="card.color">{{ card.icon }}</v-icon></div>
                <div class="pl-4 pt-2 font-weight-medium">{{ card.title }}</div>
                <v-card-text class="pt-2">{{ card.text }}</v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    data: () => ({
        cards: [
            {
                icon: 'mdi-file-document-multiple-outline',
                color: 'indigo',
                title: "HITs",
                text: "Create, customize, or remove task batches.",
                actionLabel: "Manage HITs",
                to: "hit"
            },
            {
                icon: 'mdi-account-group',
                color: 'green',
                title: "Workers",
                text: "Check workers' status, block or contact workers, or grant qualifications.",
                actionLabel: "Manage workers",
                to: "worker"
            },
            {
                icon: 'mdi-certificate',
                color: 'amber',
                title: "Qualifications",
                text: "Create or remove qualifications.",
                actionLabel: "Manage qualifications",
                to: "qual"
            },
            {
                icon: 'mdi-comment-check-outline',
                color: 'pink',
                title: "Assignments",
                text: "See and approve/deny assignments.",
                actionLabel: "Manage assignments",
                to: "assignment"
            },
        ]
    })
}
</script>
